import React from 'react'
import PropTypes from 'prop-types'
import Imgix from 'react-imgix'
import { Link } from 'gatsby'
import { baseImgixURL } from '#globals/constants'

function Logo({ className, width }) {
  return (
    <Link to='/' className={className} aria-label='logo'>
      <Imgix
        src={`${baseImgixURL}logo.png`}
        htmlAttributes={{ alt: 'logo' }}
        alt='logo'
        width={width || 110}
      />
    </Link>
  )
}
Logo.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}
export default Logo
