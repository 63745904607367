import React from 'react'
import { css } from '@emotion/core'
import { spacer, colors } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Link, {
  ExternalLink,
  ExternalLinkBtn,
} from '@dqp/common/components/Link/Link'
import routes, {
  hamburgerMenuRoutes,
  affiliateSiteLinks,
} from '#globals/routes'

const styles = {
  container: css`
    padding-bottom: 89px;
  `,
  li: css`
    ${spacer.mrB10};
    &:last-child {
      margin-bottom: 0;
    }
  `,
}

const textStyleProps = {
  as: 'li',
  color: colors.black,
  size: 'xLarge',
}

// eslint-disable-next-line react/prop-types
function PublicHamburgerMenu({ isLoggedIn }) {
  return (
    <div css={styles.container}>
      <ul>
        {!isLoggedIn && (
          <li
            css={css`
              margin-bottom: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
            `}
          >
            <ExternalLinkBtn
              size='medium'
              variant='black'
              inline
              to={routes.login}
              css={css`
                ${spacer.mrR20};
                margin-bottom: auto;
                margin: 40px 20px auto 0;
              `}
            >
              LOGIN
            </ExternalLinkBtn>
            <ExternalLinkBtn
              size='medium'
              variant='black'
              inline
              to={routes.signup}
              css={css`
                margin: 40px 0 40px 0;
              `}
            >
              TRY FOR FREE
            </ExternalLinkBtn>
          </li>
        )}
        {hamburgerMenuRoutes.map(route => (
          <Text {...textStyleProps} css={styles.li} key={route.text}>
            <Link to={route.to} className='mb-0 pb-0'>
              {route.text}
            </Link>
          </Text>
        ))}
      </ul>

      <div
        css={css`
          border-top: 1px solid ${colors.black};
          margin: 40px 0;
        `}
      />

      <ul>
        <li
          css={css`
            ${styles.li};
          `}
        >
          <Text
            {...textStyleProps}
            as='h4'
            weight='bold'
            size='large'
          >
            Affiliate Sites
          </Text>
        </li>

        {affiliateSiteLinks.map(({ icon: Icon, text, to }) => (
          <Text
            {...textStyleProps}
            size='large'
            key={text}
            css={styles.li}
          >
            <Icon css={spacer.mrR10} />
            <ExternalLink
              to={to}
              className='mb-0 pb-0'
              target='_blank'
            >
              {text}
            </ExternalLink>
          </Text>
        ))}
      </ul>
    </div>
  )
}

export default PublicHamburgerMenu
