import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { debounce } from 'lodash-es'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { misc, colors, mixins } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import { Cross } from '@dqp/common/components/icons'
import { useEvent } from 'react-use'
import Logo from '../Logo'
import PublicHamburgerMenu from './PublicHamburgerMenu'

const styles = {
  container: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    will-change: transform;
    z-index: ${misc.hamburgerMenuZIndex};
    background-color: ${colors.yellow};
    width: 100%;
    ${mixins.transition()};
  `,
}

function HamburgerMenu({ isOpen, closeMenu }) {
  const ctr = useRef(null)
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(ctr.current)
    } else {
      enableBodyScroll(ctr.current)
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])
  const setHeight = () => {
    if (!ctr.current) return
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    ctr.current.style.height = `${height}px`
  }
  const onResize = useCallback(debounce(setHeight, 200), [])
  useEvent('resize', onResize)
  useEffect(setHeight, [])
  return (
    <div
      ref={ctr}
      css={css`
        ${styles.container};
        transform: ${isOpen ? 'translateX(0)' : 'translateX(100%)'};
      `}
    >
      <div
        className='container d-flex justify-content-between align-items-center'
        css={css`
          padding: 10px 15px;
          height: 76px;
        `}
      >
        <Logo />
        <Button onClick={closeMenu} aria-label='close-menu'>
          <Cross />
        </Button>
      </div>
      <div className='container'>
        <PublicHamburgerMenu />
      </div>
    </div>
  )
}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
}

export default HamburgerMenu
