import {
  BlueShieldOnly,
  GreenShieldOnly,
  GreyShieldOnly,
} from '@dqp/common/components/icons'

const routes = {
  home: `/`,
  about: `/about/`,
  advice: `/advice/`,
  contact: `/contact/`,
  faq: `/faq/`,
  howitworks: `/how-it-works/`,
  pricing: `/pricing/`,
  resetpassword: `/reset-password/`,
  verifyemail: `/verif-yemail/`,
  privacy: `/privacy/`,
  terms: `/terms/`,
  signup: `${process.env.DASHBOARD_ENDPOINT}/signup/`,
  login: `${process.env.DASHBOARD_ENDPOINT}/login/`,
}

export const externalSites = {
  examPaperPlus: `https://exampapersplus.co.uk/`,
  preTestPlus: `https://pretestplus.co.uk/`,
  markSchemePlus: `https://exampapersplus.co.uk/mark-schemes/`,
  facebook: `https://www.facebook.com/exampapersplus/`,
  twitter: `https://twitter.com/exampapersplus`,
}

export const headerRoutes = [
  { to: routes.howitworks, text: `How it Works` },
  { to: routes.advice, text: `Advice` },
  { to: routes.pricing, text: `Pricing` },
  { to: routes.about, text: `About` },
  { to: routes.contact, text: `Contact` },
]

export const hamburgerMenuRoutes = [
  { to: routes.home, text: `Home` },
  ...headerRoutes,
  { to: routes.faq, text: `FAQs` },
]
export const affiliateSiteLinks = [
  {
    to: externalSites.examPaperPlus,
    text: `Exam Papers Plus`,
    icon: BlueShieldOnly,
    external: true,
  },
  {
    to: externalSites.preTestPlus,
    text: `Pretest Plus`,
    icon: GreenShieldOnly,
    external: true,
  },

  {
    to: externalSites.markSchemePlus,
    text: `Mark Schemes Plus`,
    icon: GreyShieldOnly,
    external: true,
  },
]

export const footerRoutes = [
  {
    title: `Company`,
    routeMap: [
      { to: routes.howitworks, text: `How it Works` },
      { to: routes.advice, text: `Advice` },
      { to: routes.pricing, text: `Pricing` },
      { to: routes.about, text: `About` },
    ],
  },
  {
    title: `Help`,
    routeMap: [
      { to: routes.faq, text: `FAQs` },
      { to: routes.contact, text: `Contact` },
    ],
  },
  {
    title: `Our Sites`,
    routeMap: affiliateSiteLinks,
  },
]

export default routes

export const testRoutes = [
  { to: routes.howitworks, text: `How it Works`, external: false },
  { to: routes.advice, text: `Advice`, external: false },
  { to: routes.pricing, text: `Pricing`, external: false },
  { to: routes.about, text: `About`, external: false },
  { to: routes.contact, text: `Contact`, external: false },
]
