import React from 'react'
import { css } from '@emotion/core'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
  AccordionItemState,
} from 'react-accessible-accordion'
import { spacer, mixins } from '@dqp/common/styles/utilities'
import { ChevronRight } from '@dqp/common/components/icons'
import Link, { ExternalLink } from '@dqp/common/components/Link/Link'
import { footerRoutes } from '#globals/routes'

const styles = {
  container: css`
    .accordion__item {
      border-bottom: 1px solid #dedede;
    }
    .accordion__button {
      color: black;
      font-size: 24px;
      font-weight: bold;
      background: white;
      padding: 20px 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      outline: none;
    }
    .accordion__heading {
    }
    .accordion__panel {
      padding: 20px 0;
      padding-top: 0;
      animation: fadein 0.35s ease-in;
      display: flex;
      flex-direction: column;
    }
    ${mixins.transition()};
    ${spacer.mrB40};
    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 100%;
      }
    }
  `,
  icon: expanded => css`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 25px;
    height: 25px;
    color: #979797;
    transform: ${expanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
    transform-origin: 50% 50%;
    ${mixins.transition()}
  `,
}
function FooterAccordions() {
  return (
    <div css={styles.container} className='accordion-wrapper-div'>
      <Accordion allowZeroExpanded>
        {footerRoutes.map(item => (
          <AccordionItem key={item.title}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {item.title}{' '}
                <AccordionItemState>
                  {({ expanded }) => (
                    <span css={styles.icon(expanded)}>
                      <ChevronRight />
                    </span>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {item.routeMap.map(route => {
                const Tag = route.external ? ExternalLink : Link
                return (
                  <Tag
                    className='d-block'
                    to={route.to}
                    key={route.text}
                    {...(route.external && { target: '_blank' })}
                  >
                    {route.text}
                  </Tag>
                )
              })}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default FooterAccordions
