import React from 'react'
import { spacer } from '@dqp/common/styles/utilities'
import Logo from '../Logo'
import FooterAccordions from './FooterAccordions'
import SocialMediaLinks from './SocialMediaLinks'

function MobileFooter() {
  return (
    <div className='container'>
      <FooterAccordions />
      <div className='d-flex py-3 justify-content-between align-items-center'>
        <Logo css={spacer.mrR30} />
        <SocialMediaLinks />
      </div>
    </div>
  )
}

export default MobileFooter
