import React from 'react'
import PropTypes from 'prop-types'
import PublicFooter from './PublicFooter/PublicFooter'
import PublicHeader from './PublicHeader/PublicHeader'
import routes, {
  testRoutes,
  affiliateSiteLinks,
} from '#globals/routes'

const PublicLayout = ({ children, headerColor }) => {
  return (
    <>
      <PublicHeader
        headerColor={headerColor}
        routes={testRoutes}
        affiliateSiteLinks={affiliateSiteLinks}
        loginRoute={{ to: routes.login, external: true }}
        signupRoute={{ to: routes.signup, external: true }}
      />
      <main>{children}</main>
      <PublicFooter />
    </>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerColor: PropTypes.string.isRequired,
}

export default PublicLayout
