import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const logo = `${process.env.IMGIX_ENDPOINT}/logo.png`

function SEO({
  description,
  meta,
  title,
  image,
  articleStructuredData,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const {
    siteUrl,
    // telephone,
    author,
    title: siteOrganisation,
  } = site.siteMetadata

  const structuredData = {
    '@context': 'https://schema.org/',
    '@graph': [
      {
        '@type': 'WebSite',
        name: siteOrganisation,
        url: siteUrl,
      },
      {
        '@type': 'Organization',
        name: siteOrganisation,
        url: siteUrl,
        logo,
        // contactPoint: {
        //   '@type': 'ContactPoint',
        //   telephone,
        //   contactType: 'customer service',
        //   areaServed: 'GB',
        //   availableLanguage: 'en',
        // },
      },
    ],
  }

  if (Object.keys(articleStructuredData).length) {
    const { slug: slugIgnored, ...rest } = articleStructuredData
    structuredData['@graph'].push({
      '@type': 'Article',
      author: {
        '@type': 'Organization',
        name: siteOrganisation,
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${siteUrl}/${articleStructuredData.slug}`,
      },
      publisher: {
        '@type': 'Organization',
        name: siteOrganisation,
        logo: {
          '@type': 'ImageObject',
          url: logo,
        },
      },
      ...rest,
    })
  }
  // eslint-disable-next-line import/no-dynamic-require
  const getFavicon = path => require(`#images/favicons/${path}`)
  return (
    <Helmet
      htmlAttributes={{
        lang: `en`,
      }}
      title={title}
      titleTemplate={`%s | ${siteOrganisation}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
    >
      <script type='application/ld+json'>
        {JSON.stringify(structuredData)}
      </script>

      <link
        rel='apple-touch-icon-precomposed'
        sizes='57x57'
        href={getFavicon('apple-touch-icon-57x57.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='114x114'
        href={getFavicon('apple-touch-icon-114x114.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='72x72'
        href={getFavicon('apple-touch-icon-72x72.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='144x144'
        href={getFavicon('apple-touch-icon-144x144.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='60x60'
        href={getFavicon('apple-touch-icon-60x60.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='120x120'
        href={getFavicon('apple-touch-icon-120x120.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='76x76'
        href={getFavicon('apple-touch-icon-76x76.png')}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='152x152'
        href={getFavicon('apple-touch-icon-152x152.png')}
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-196x196.png')}
        sizes='196x196'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-96x96.png')}
        sizes='96x96'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-32x32.png')}
        sizes='32x32'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-16x16.png')}
        sizes='16x16'
      />
      <link
        rel='icon'
        type='image/png'
        href={getFavicon('favicon-128.png')}
        sizes='128x128'
      />
      <meta name='application-name' content='&nbsp;' />
      <meta name='msapplication-TileColor' content='#FFFFFF' />
      <meta
        name='msapplication-TileImage'
        content={getFavicon('mstile-144x144.png')}
      />
      <meta
        name='msapplication-square70x70logo'
        content={getFavicon('mstile-70x70.png')}
      />
      <meta
        name='msapplication-square150x150logo'
        content={getFavicon('mstile-150x150.png')}
      />
      <meta
        name='msapplication-wide310x150logo'
        content={getFavicon('mstile-310x150.png')}
      />
      <meta
        name='msapplication-square310x310logo'
        content={getFavicon('mstile-310x310.png')}
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  articleStructuredData: PropTypes.object,
}
SEO.defaultProps = {
  image: '',
  articleStructuredData: {},
}
export default SEO

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
