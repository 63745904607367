import React from 'react'
import { css } from '@emotion/core'
import { spacer, colors } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'
import MobileFooter from './MobileFooter'
import DesktopFooter from './DesktopFooter'
import routes from '#globals/routes'
import amex from '#images/amex.svg'
import applePay from '#images/apple-pay.svg'
import googlePay from '#images/google-pay.svg'
import mastercard from '#images/mastercard.svg'
import visa from '#images/visa.svg'

const styles = {
  icon: css`
    width: 40px;
    ${spacer.mrR10};
    fill: black !important;
    :last-child {
      ${spacer.mrR0}
    }
  `,
}
function PublicFooter() {
  return (
    <footer>
      <div
        className='d-none d-lg-flex align-items-center'
        css={css`
          flex-grow: 1;
        `}
      >
        <DesktopFooter />
      </div>
      <div className='d-block d-lg-none'>
        <MobileFooter />
      </div>

      <div
        css={css`
          height: 70px;
        `}
      />
      <div
        className='container text-lg-left text-center'
        css={spacer.mrB20}
      >
        <img css={styles.icon} src={amex} alt='amex-logo' />
        <img
          css={styles.icon}
          src={mastercard}
          alt='mastercard-logo'
        />
        {/* <img css={styles.icon} src={applePay} alt='applePay-logo' /> */}
        {/* <img css={styles.icon} src={googlePay} alt='googlePay-logo' /> */}
        <img css={styles.icon} src={visa} alt='visa-logo' />
        <Text size='xSmall' css={spacer.mrT10} color={colors.black}>
          &copy;{new Date().getFullYear()} All rights reserved Daily
          Quest Plus | <Link to={routes.privacy}>Privacy</Link> |{' '}
          <Link to={routes.terms}>Terms</Link>
        </Text>
      </div>
    </footer>
  )
}

export default PublicFooter
